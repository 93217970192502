<script>
export default {
	data() {
		return {
			items: this.$srv('Home_G_Related', []),
		}
	},
}
</script>

<template>
	<Media
		v-if="items.products.length"
		:src="items.bgImg"
		alt=""
		width="100%"
		max-height="850px"
		cover
		class="py-16 px-md-10 px-lg-16"
	>
		<Container class="py-6 d-flex align-center mb-5">
			<Media :src="items.titleImg" alt="" contain max-width="261px" class="d-none d-md-block" eager />
			<div class="font-5 font-sm-8 font-weight-bold ml-5 darkblue--text">
				{{ items.title }}
			</div>
		</Container>
		<Container :fluid="{ xs: true, md: false }">
			<ProductsCarousel :products="items.products" list-id="home-products-carousel" />
		</Container>
	</Media>
</template>
<style scoped>
.carousel >>> .arrow-icon {
	color: var(--v-darkblue-base) !important;
}
</style>
