<script>
export default {
	lang: 'home',
	computed: {
		negativeMargin() {
			return this.$vuetify.breakpoint.smAndDown ? 80 : 127
		},
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div class="home-container">
		<Home-A-Sliders />
		<Home-A-BooksSliders />
		<Home-B-Cards />
		<Home-C-HowWorks />
		<Home-D-Info />
		<Home-E-BigImage />
		<Home-F-MoreInfo />
		<Home-G-Cards />
		<Home-H-Reviews />
		<Home-I-Newsletter />
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.home-container {
	margin-top: -$navh;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.home-container {
		margin-top: -$navhxs;
	}
}
</style>
