<script>
export default {
	data() {
		return {
			data: this.$srv('Home_E_BigImage'),
		}
	},
}
</script>

<template>
	<div class="pt-16" v-if="data">
		<SafeLink :to="data.imgLink">
			<Media
				:src="$vuetify.breakpoint.xs ? data.imgMobile : data.img"
				:aspect-ratio="$vuetify.breakpoint.xs ? 0.6 : 2.0915"
				contain
				eager
			/>
		</SafeLink>
	</div>
</template>
